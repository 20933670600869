<template>
  <div class="knowledge-search-page">
    <van-search
      v-model="params.search_content"
      show-action
      placeholder="搜索"
      @search="onSearch"
      @cancel="onCancel"
    />
    <div class="search-content">
      <sendListItem
        v-for="(item, index) in list"
        :key="index"
        :sendItem="item"
        pageType="knowledge"
        :limitNum="5"
        :mediaList="item.file_arr"
        :customer_userid="customer_userid"
        :chat_id="chat_id"
        :member_userid="member_userid"
        :index="index"
        :keyArr="keyArr"
        backgroundColor="#fff"
        cutColor="#f5f5f5"
      ></sendListItem>
      <div v-if="list.length === 0 && searched" class="search-nodata">
        暂无搜索结果
      </div>
    </div>
  </div>
</template>

<script>
import { Search } from 'vant'
import sendListItem from '../../../../components/quickSend/sendListItem.vue'
import { getKnowledgeList } from '../../../../service/quickSend.service'
import {
  getCurExternalContact,
  getCurExternalChat
} from '../../../../utils/nwechat.js'
import { res } from '../tabPage/test'
export default {
  components: {
    [Search.name]: Search,
    sendListItem: sendListItem
  },
  data() {
    return {
      list: [],
      customer_userid: '',
      chat_id: '',
      member_userid: '',
      params: {
        group_id: '',
        search_content: ''
      },
      keyArr: [],
      searched: false,
      requestStatus: null,
      pollingIndex: 0
    }
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.requestStatus)
  },
  created() {
    // this.list = res.data || []
    // this.onSearch()
    this.customer_userid = this.$route.query.customer_userid || ''
    this.chat_id = this.$route.query.chat_id || ''
    this.init()
    console.log('搜索页id', this.customer_userid, this.chat_id)
  },
  methods: {
    init() {
      // 轮询请求客户id
      this.requestStatus = setInterval(() => {
        if (this.customer_userid || this.chat_id || this.pollingIndex > 1) {
          console.log('搜索页轮询次数-最终-count', this.pollingIndex)
          clearInterval(this.requestStatus)
          return
        }
        // console.log('现在是第', this.pollingIndex)
        console.log('搜索页轮询次数-count', this.pollingIndex)
        this.pollingIndex++
        getCurExternalContact()
          .then((res) => {
            this.customer_userid = res
          })
          .catch(() => {
            // this.customer_userid = ''
          })
        getCurExternalChat()
          .then((res) => {
            // console.log('resres群', res)
            this.chat_id = res
          })
          .catch(() => {
            // this.chat_id = ''
          })
      }, 50)
    },
    // 搜索
    onSearch() {
      this.list = []
      if (!this.params.search_content) {
        this.searched = true
        return
      }

      // 避免转化为对象
      // let arr = []
      // arr.push(this.params.search_content)
      // this.keyArr = arr
      this.keyArr[0] = this.params.search_content
      getKnowledgeList(this.params)
        .then((res) => {
          console.log(res)
          if (res.code == 200) {
            this.list = res.data || []
          }
          this.searched = true
        })
        .catch((e) => {
          console.log(e)
        })
    },
    onCancel() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.knowledge-search-page {
  .search-content {
    box-sizing: border-box;
    // width: 295px;
    background-color: #f5f5f5;
    height: calc(100vh - 55px);
    padding: 20px 15px;
    overflow-y: scroll;
    position: relative;
  }

  .search-nodata {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin: auto;
    color: #909399;
    text-align: center;
    font-size: 14px;
  }
}
</style>
